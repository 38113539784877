//Vars
$assetPath: "../images";

//Functions
@function get-path-to-assets($assetPath) {
  @return $assetPath;
}

$loginhighlight: rgba(120, 187, 57, 1.000);

body.login {
  background-color: #cbcbcb;
  //background: rgb(53,106,160);
  //background: linear-gradient(to bottom, rgba(125,185,232,1) 0%,rgba(41,137,216,1) 46%,rgba(32,124,202,1) 82%,rgba(30,87,153,1) 100%);
  background: url(#{$assetPath}/login/login-background.jpg) repeat-x fixed 0 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

}

.login h1 a {
  background-image: url(#{$assetPath}/login/logo.png);
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  color: #444444;
  //height: 53px;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3em;
  margin: 0 auto 25px;
  padding: 0;
  text-decoration: none;
  width: 100%;
  text-indent: -9999px;
  outline: none;
  overflow: hidden;
  display: block;

}

.login form {
  margin-top: 20px;
  margin-left: 0;
  padding: 26px 24px 46px;
  font-weight: 400;
  overflow: hidden;
  background: rgba(222, 222, 222, 0.87);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 1);
  border-radius: 5px;
}

.login label {
  font-size: 12px;
  color: #555555;
}

.login #nav a, .login #backtoblog a {
  text-decoration: none;
  color: #ffffff;
}

.login #nav a:hover, .login #backtoblog a:hover, .login h1 a:hover {
  color: darken($loginhighlight, 10%);
}

.login input[type="text"] {
  background-color: #ffffff;
  border-color: #dddddd;
  font-size: 12px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  -webkit-border-radius: 4px;
}

.login input[type="password"] {
  background-color: #ffffff;
  border-color: #dddddd;
  padding: 10px;
  font-size: 12px;
  -webkit-border-radius: 4px;
}

.login .button-primary {
  //width: 120px;
  float: right;
  background-color: $loginhighlight !important;

  //background: linear-gradient(top, rgba(130, 195, 67, 1.000), rgba(130, 195, 67, 1.000));

  //background-image: linear-gradient(top, rgba(130, 195, 67, 1.000) 0%, rgba(130, 195, 67, 1.000) 100%);
  color: #ffffff;
  -webkit-border-radius: 4px;
  border: none;
  text-shadow: none;
}

.login .button-primary:hover {
  background-color: $loginhighlight !important;

  color: #ffffff;
  -webkit-border-radius: 4px;
  border: 1px solid darken($loginhighlight, 10%);
}

.login .button-primary:active {
  background-color: darken($loginhighlight, 10%) !important;

  color: #ffffff;
  -webkit-border-radius: 4px;
  border: 1px solid darken($loginhighlight, 10%);
}

.login #login_error {
  border-left-color: #dc3232;
  background-color: #dc3232;
  color: #ffffff;
  a {
    color: #ffb9ba;
  }
}

//Social Login Styles

#branding {
  display: none;
}



